import React, { useEffect, useRef, useState } from 'react';

//styles
import classes from './bannervideo.module.css';

const shortVideoLink = 'https://res.cloudinary.com/ahumareze/video/upload/v1681900956/Gigmile_Brex_1_kqlfwg.mp4';
// const longVideoLonk = 'https://res.cloudinary.com/ahumareze/video/upload/v1681740380/gigmile_brex_h5ew4o.mp4'

function BannerVideo({videoRef}) {
    

    return (
        <div className={classes.container}>
            <video ref={videoRef} autoPlay loop className={classes.videoContainer} >
                <source src={shortVideoLink} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    )
}

export default BannerVideo