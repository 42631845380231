import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//styles
import classes from './inviteBanner.module.css';

//images
import inviteBanner from '../../../../assets/images/inviteBanner.jpg';
import inviteBannerSmall from '../../../../assets/images/inviteBannerSmall.jpg';

//components
import { Button } from '../../../../components';

//aos
import AOS from 'aos';
import 'aos/dist/aos.css';

//paths
import * as paths from '../../../../constants/paths';

const width = window.innerWidth;

function InviteBanner() {
    //initialize
    const navigate = useNavigate();

    useEffect(() => {
        AOS.init()
    }, []);

    return (
        <div className={classes.container} style={{backgroundImage: `url(${width > 500 ? inviteBanner : inviteBannerSmall})`}} >
            <p data-aos="fade-up">The UP-AND-COMERS</p>
            <h1 data-aos="fade-up">Join the community of gig workers who are improving their livelihoods</h1>
            <div data-aos="fade-up">
                <Button text={'Contact Us'} onClick={() => navigate(paths.contact)} />
            </div>
        </div>
    )
}

export default InviteBanner