import React from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';

//styles
import classes from './economyimpact.module.css';

//images
import impactIcon1 from '../../../../assets/svgs/impactIcon1.svg';
import impactIcon2 from '../../../../assets/svgs/impactIcon2.svg';
import impactIcon3 from '../../../../assets/svgs/impactIcon3.svg';
import impactIcon4 from '../../../../assets/svgs/impactIcon4.svg';

function EconomyImpact() {

    const FlexItem = ({img, title, content}) => {
        return(
            <div className={classes.flexItem}>
                <img src={img} />
                <h3>{title}</h3>
                <p>{content}</p>
            </div>
        )
    };
    
    return (
        <div className={classes.container}>
            <h1>Our Impact in the Gig Economy</h1>
            <ScrollContainer className={classes.flexContainer}>
                <FlexItem img={impactIcon1} title={'Financial Inclusion'} content={'We partner with financial institutions and other service providers to offer a range of financial products and services such as credits, savings, bill payments  and virtual accounts. We ensure all gig workers in our community have access to an account.'} />
                <FlexItem img={impactIcon2} title={'Economic Epowerment'} content={"We are dedicated to empowering mobility based gig workers in Africa by providing them with productive assets and working capital to ensure they can stay productive. Our efforts sees their earnings increase by over 200% with an average monthly income of $300+, and a clear path towards asset ownership."} />
                <FlexItem img={impactIcon3} title={'Environmental Sustainability'} content={"As a forward-thinking company, we recognize the impact that climate change has on our planet, and we are committed to making a positive difference by introducing electric vehicles (EVs) to our fleet. By introducing EVs, we are taking a step towards reducing our carbon footprint and promoting sustainability"}  />
                <FlexItem img={impactIcon4} title={'Digital Inclusion'} content={"Digital literacy is a vital piece of our value offering to the gig workers in our community. We continually train them both online and offline to ensure they are lifelong learners whilst also ensuring they have an equitable access to the use of information and communication technologies."} />
            </ScrollContainer>
        </div>
    )
}

export default EconomyImpact