import React, { useEffect, useState } from 'react';

//styles
import classes from './openroles.module.css';

//reacticons
import { FiSearch } from 'react-icons/fi';

//paths
import * as paths from '../../../../constants/paths';

//data
import  roles from '../../../../data/roles.json';

//aos
import AOS from 'aos';
import 'aos/dist/aos.css';

//select options
const selectLoation = [{title: "All locations", value: ""},{title: "Lagos, Nigeria", value: "lagos"},{title: "Accra, Ghana", value: "accra"}]
const selectDepartment = [{title: "All departments", value: ""},{title: "Technology", value: "tech"},{title: "Operations", value: "operations"}]

function OpenRoles() {
    //UI state
    const [filteredList, setFilteredList] = useState(roles.roles);

    useEffect(() => {
        AOS.init()
    }, []);

    const handleFilterLocation = (e) => {
        const data = roles.roles;

        const newArr = e ? data.filter((item) => item.main_location === e) : data;

        setFilteredList(newArr); 
    }

    const handleFilterDepartment = (e) => {
        const data = roles.roles;

        const newArr = e ? data.filter((item) => item.department === e) : data;

        setFilteredList(newArr); 
    };

    const handleSearch = (e) => {
        const data = roles.roles;

        const newArr = data.filter((item) => item.title.toLowerCase().includes(e.toLowerCase()));

        setFilteredList(newArr); 
    }

    const RoleItem = ({title, location, link, content}) => {

        const shortened = content.split(" ").slice(0, 25).join(" ") + '...';

        return(
            <div className={classes.roleItem}>
                <a href={paths.careers + link}><h3>{title}</h3></a>
                <p className={classes.content}>{shortened}</p>
                <p className={classes.location}>{location}</p>
            </div>
        )
    };

    return (
        <div className={classes.container} id='open-roles'>
            <div className={classes.main}  data-aos="fade-up">
                <h1>We have {roles.roles.length} Open Roles</h1>
                <p className={classes.introText}>Ready to kickstart your journey with Gigmile? Come join us.</p>
                <div className={classes.mainContainer}>
                    <div className={classes.filterSection}>
                        <div className={classes.searchBar}> <FiSearch size={20} /> <input placeholder='Search roles' onChange={e => handleSearch(e.target.value)} /> </div>
                        <select onChange={e => handleFilterDepartment(e.target.value)}>
                            {selectDepartment.map((i, idx) => (
                                <option value={i.value} key={idx}>{i.title}</option>
                            ))}
                        </select>
                        <select onChange={e => handleFilterLocation(e.target.value)}>
                            {selectLoation.map((i, idx) => (
                                <option value={i.value} key={idx}>{i.title}</option>
                            ))}
                        </select>
                    </div>
                    <div className={classes.rolesContainer}>
                        {filteredList.map((i,idx) => (
                            <RoleItem
                                title={i.title}
                                location={i.location}
                                link={i.link}
                                key={idx}
                                content={i.description}
                            />
                        ))}
                        {filteredList.length === 0 && <p className={classes.emptyState}>Noting matches your search, try searching a different keyword</p>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OpenRoles