import React from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';

//styles
import classes from './help.module.css';

//icons
import { BsArrowRight } from 'react-icons/bs';

//paths
import * as paths from '../../../../constants/paths';

function Help() {

    const HelpItem = ({title, content, linkText, link }) => {
        return(
            <div className={`${classes.ValueItem}`}>
                <h3>{title}</h3>
                <p>{content}</p>
                <a href={link}>
                    <div>
                        {linkText} <BsArrowRight size={18} />
                    </div>
                </a>
            </div>
        )
    };

    return (
        <div className={classes.container}>
            <h1>Gigmile <span>Help</span></h1>
            <div className={classes.three_grid}>
                <HelpItem title={'Help Center'} content={'Here you can find answers to questions and get assistance with any issues you may be experiencing'} linkText={'Visit FAQs'} link={paths.faq} />
                <HelpItem title={'Send a Mail'} content={'Having an issue moving on? We are always ready to attend to your needs just a mail away.'} linkText={'wecare@gigmile.com'} link={'mailto:kayode@gigmile.com'} />
                <HelpItem title={'Chat Us'} content={'Get quick and reliable support with all your enquiries! Our bot is ready to provide answers to your questions.'} linkText={'Chat Support'} link={paths.contact} />
            </div>
        </div>
    )
}

export default Help