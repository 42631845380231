import React from 'react';

//styles
import classes from './mission.module.css';

//images
import mission3 from '../../../../assets/images/mission3.jpg';
import mission4 from '../../../../assets/images/mission4.jpg';
const mission1 = 'https://res.cloudinary.com/ahumareze/image/upload/v1681914124/founded_g2ifge.jpg';
const mission2 = 'https://res.cloudinary.com/ahumareze/image/upload/v1681849080/20230123_181029_1_e2wzju.jpg';

function Mission() {

    const MissionItem = ({img, title, amount}) => {
        return(
            <div className={classes.Mission}>
                <img src={img} alt={'title'} />
                <div>
                    <p>{title}</p>
                    <h2>{amount}</h2>
                </div>
            </div>
        )
    };

    return (
        <div className={classes.container}>
            <h1>Our mission is to unlock the power of a <span>borderless Gig economy</span></h1>
            <p className={classes.introText}>With a projected working-age population of 1.6 billion and a third of the world's young population by 2050, Africa holds enormous potential for economic growth. Currently, the gig and informal economy, though highly fragmented - already contributes between 25% to 65% of the GDP in sub-Saharan Africa and provides over 50% of non-agricultural employment. This presents a significant opportunity to provide financial services to a rapidly expanding market.</p>
            <div className={classes.statsContainer}>
                <MissionItem title={'Founded'} amount={'2022'} img={mission1} />
                <MissionItem title={'Presence in'} amount={'🇳🇬 & 🇬🇭'} img={mission2} />
                <MissionItem title={'Assets Deployed'} amount={'800+'} img={mission3} />
                <MissionItem title={'Amazing Team Members'} amount={'25+'} img={mission4} />
            </div>
        </div>
    )
}

export default Mission