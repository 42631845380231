import React from 'react';

//styles
import classes from './description.module.css';
import { Button } from '../../../../components';

function Description({data}) {
    return (
        <div className={classes.container}>
            <div className={classes.section}>
                <h3>Description</h3>
                <p>{data.description}</p>
            </div>
            <div className={classes.section}>
                <h3>Responsibilities</h3>
                <ul>
                    {data.responsibilities.map((i, idx) => (
                        <li key={idx}>{i}</li>
                    ))}
                </ul>
            </div>
            <div className={classes.section}>
                <h3>Requirements</h3>
                <ul>
                    {data.requirements.map((i, idx) => (
                        <li key={idx}>{i}</li>
                    ))}
                </ul>
            </div>
            <div className={classes.section}>
                <h3>Education</h3>
                <ul>
                    {data.education.map((i, idx) => (
                        <li key={idx}>{i}</li>
                    ))}
                </ul>
            </div>
            <div className={classes.section}>
                <h3>Experience</h3>
                <ul>
                    {data.experience.map((i, idx) => (
                        <li key={idx}>{i}</li>
                    ))}
                </ul>
            </div>
            <div className={classes.section}>
                <h3>Benefits</h3>
                <ul>
                    {data.benefits.map((i, idx) => (
                        <li key={idx}>{i}</li>
                    ))}
                </ul>
            </div>
            <div className={classes.button}>
                <a href={data.applicationLink} target="_blank"><Button text={'Apply'} onClick={() => console.log('hello world')} /></a>
            </div>
        </div>
    )
}

export default Description