import React from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';

//styles
import classes from './perks.module.css';

//images
import salary from '../../../../assets/svgs/salary.svg';
import pension from '../../../../assets/svgs/pension.svg';
import leave from '../../../../assets/svgs/leave.svg';
import healtcare from '../../../../assets/svgs/healtcare.svg';

//data
import perks from '../../../../data/perks.json';

const width = window.innerWidth;

function Perks() {
    const paddedData = (width - 1200) / 2;

    const PerkItem = ({img, title, content}) => {
        return(
            <div className={classes.PerkItem}>
                <img src={img} />
                <h3>{title}</h3>
                <p>{content}</p>
            </div>
        )
    };

    return (
        <div className={classes.container}>
            <div className={classes.main}>
                <div className={classes.responsiveContainer}>
                    <div className={classes.top}>
                        <div /> <h3>OUR PERKS</h3>
                    </div>
                    <h1>Invest in a career that invests in you</h1>
                </div>
                
                <ScrollContainer className={classes.perksList} style={width > 1200 ? {paddingLeft: paddedData, paddingRight: paddedData} : {paddingLeft: 20, paddingRight: 20}}>
                    <PerkItem img={salary} title={perks[0].title} content={perks[0].context} />
                    <PerkItem img={healtcare} title={perks[1].title} content={perks[1].context} />
                    <PerkItem img={leave} title={perks[2].title} content={perks[2].context} />
                    <PerkItem img={pension} title={perks[3].title} content={perks[3].context} />
                </ScrollContainer>
            </div>
        </div>
    )
}

export default Perks