import React from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';

//styles
import classes from './offices.module.css';

//text images
import mission4 from '../../assets/images/mission4.jpg';

//images
const ngOffice = 'https://res.cloudinary.com/ahumareze/image/upload/v1681914327/Rectangle_18866_1_gi7ort.jpg';
const usOffice = 'https://res.cloudinary.com/ahumareze/image/upload/v1681914329/Rectangle_18866_tdl0i1.jpg';
const ghOffice = 'https://res.cloudinary.com/ahumareze/image/upload/v1681914327/Rectangle_18866_2_mxulxn.jpg'

function Offices() {

    const Office = ({img, location, phone}) => {
        return(
            <div className={classes.Office} style={{backgroundImage: `url(${img})`}}>
                <div>
                    <h3>{location}</h3>
                    <p>{phone}</p>
                </div>
            </div>
        )
    };

    return (
        <div className={classes.container}>
            <h1>Visit Our Offices Around <span>the World</span></h1>
            <ScrollContainer className={classes.officeContainer}>
                <Office img={ngOffice} location={'11 Tinube close, Ilupeju, Lagos, Nigeria'} phone={'+234 815 5401 805'} />
                <Office img={ghOffice} location={'Haasto Atomic 4, Adjacent to Melcom, Accra, Ghana'} phone={'+233 593 340 575'} />
                <Office img={usOffice} location={'2055 Limestone Rd STE 200-C, 19808, Wilmington, DE, Wilmington, US,'} phone={'+1 (506) 831 526 904'}  />
            </ScrollContainer>
        </div>
    )
}

export default Offices