import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//styles
import classes from './top.module.css';

//components
import { Button, Header } from '../../../../components';

//aos
import AOS from 'aos';
import 'aos/dist/aos.css';

//images
import homeBg from '../../../../assets/svgs/homeBg.svg';

//paths
import * as paths from '../../../../constants/paths';

function Top() {
    //initialize
    const navigate = useNavigate();

    useEffect(() => {
        AOS.init()
    }, []);

    return (
        <div className={classes.container} style={{backgroundImage: `url(${homeBg})`}}>
            <div className={classes.mainContents}>
                <Header />
                <div className={classes.main} data-aos="fade-up">
                    <h1>Gig Workers in Africa Deserve More and that's what We want to Give them</h1>
                    <p>We are on a mission to provide affordable, accessible and suitable financial services to Gig and informal workers who have historically been underdeserved and excluded from financial services</p>
                    {/* <div className={classes.buttons}>
                        <Button text={'Contact Us'} onClick={() => navigate(paths.contact)} />
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Top