import React from 'react';
import { useNavigate } from 'react-router-dom';

//styles
import classes from './goal.module.css';

//components
import { Button } from '../../../../components';

//images
import bikeFrame from '../../../../assets/images/bike-frame.svg'
import handPhone from '../../../../assets/images/hand-phone.png';
import posMachine from '../../../../assets/images/pos-machine.png';
import handCard from '../../../../assets/images/hand-card.png';

//svgs
import thunderBolt1 from '../../../../assets/svgs/thunder-bolt1.svg';
import thunderBolt2 from '../../../../assets/svgs/thunder-bolt2.svg';

//paths
import * as paths from '../../../../constants/paths';


function Goal() {
    //initialize
    const navigate = useNavigate();

    return (
        <div className={classes.container}>
            <div className={classes.main}>
                <h1>Our goal is to Empower Gig workers in Africa</h1>
                <p>We are creating a thriving ecosystem that supports the gig economy in Africa. The African gig economy is projected to reach $19 billion by 2025 with over 100 million workers participating. Interested in our mission?  Contact us to learn more.</p>
                {/* <Button text={'About Gigmile'} onClick={() => navigate(paths.aboutUs)} /> */}
            </div>
            <div className={classes.mainContainer}>
                <div className={classes.images}>
                    <img src={handCard} />
                    <img src={posMachine} />
                    <img src={bikeFrame} />
                    <img src={handPhone} />
                </div>
                <img src={thunderBolt1} className={classes.thunderBolt1} />
                <img src={thunderBolt2} className={classes.thunderBolt2} />
            </div>
            
        </div>
    )
}

export default Goal