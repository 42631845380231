import React from 'react';

//styles
import classes from './banner.module.css';

//img
const bannerImg = 'https://res.cloudinary.com/ahumareze/image/upload/v1682492506/Rectangle_18901_1_jalqas.jpg'

function Banner() {
    return (
        <div className={classes.container} style={{backgroundImage: `url(${bannerImg})`}}>
            <div className={classes.main}>
                <p className={classes.blog_title}>Gig Economy</p>
                <a href={'https://mercury.com/blog/company-news/mercury-raise-seed-fall-2022'} target='_blank'><h2>Meet our Fellow Mercury Raise Seed Fall 2022 startups</h2></a>
                <ul>
                    <li>APR 12, 2023</li>
                </ul>
            </div>
        </div>
    )
}

export default Banner