import React from 'react';

//styles
import classes from './supporters.module.css';

//images
import support1 from '../../../../assets/supporters/support1.svg';
import support2 from '../../../../assets/supporters/support2.png';
import support3 from '../../../../assets/supporters/support3.svg';
import support4 from '../../../../assets/supporters/support4.svg';
import support5 from '../../../../assets/supporters/support5.svg';
import support6 from '../../../../assets/supporters/support6.svg';
import support7 from '../../../../assets/supporters/support7.svg';
import support8 from '../../../../assets/supporters/support8.svg';
import support9 from '../../../../assets/supporters/support9.svg';
import support10 from '../../../../assets/supporters/support10.svg';
import support11 from '../../../../assets/supporters/support11.svg';
import support12 from '../../../../assets/supporters/support12.svg';
import support13 from '../../../../assets/supporters/sonlink.svg';

function Supporters() {
    return (
        <div className={classes.container}>
            <h1>Our <span>Supporters</span></h1>
            <div className={classes.supporters_container}>
                <img src={support1} alt='' />
                <img src={support3} alt='' />
                <img src={support4} alt='' />
                <img src={support2} alt='' />
                <img src={support9} alt='' />
                <img src={support13} alt='' />
                <img src={support5} alt='' />
                <img src={support10} alt='' />
                <img src={support8} alt='' />
                <img src={support7} alt='' />
                <img src={support6} alt='' />
                <img src={support11} alt='' />
                <img src={support12} alt='' />
            </div>
        </div>
    )
}

export default Supporters