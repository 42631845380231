import React from 'react';

//styles
import classes from './press.module.css';

//component
import { Footer, Header } from '../../components';
import { Banner } from './components';

//DATA
import press from '../../data/press.json';

function Press() {

    const PressItem = ({img, date, title, introText, writer, link, tag}) => {
        return(
            <div className={classes.BlogItem}>
                <img src={img} alt='' />
                <div className={classes.mainBlogContainer}>
                    <ul> <li>{date}</li> <li>3 MINS READ</li> </ul>
                    <div className={classes.tag}>{tag}</div>
                    <a href={link} target='_blank'><h3>{title}</h3></a>
                    <p className={classes.introText}>{introText}</p>
                    <p className={classes.writer}>{writer}</p>
                </div>
            </div>
        )
    };

    return (
        <div className={classes.container}>
            <Header />
            <div className={classes.top}>
                <h1>Gigmile in the News</h1>
                <p>For general press inquiries or media requests, please get in touch at news@gigmile.com</p>
            </div>
            <Banner />
            <div className={classes.filter}>
                <div className={classes.activeDiv}>All</div>
                <div>Product Updates</div>
                <div>Announcements</div>
                <div>Gig Economy</div>
                <div>Financial Services</div>
                <div>Fintech</div>
            </div>
            <div className={classes.contents_container}>
                {press.map((i, idx) => (
                    <PressItem
                        img={i.img}
                        title={i.title}
                        introText={i.intro_text}
                        date={i.date}
                        writer={i.writer}
                        link={i.link}
                        tag={i.tag}
                    />
                ))}
            </div>
            <Footer />
        </div>
    )
}

export default Press