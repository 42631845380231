import React, { useEffect } from 'react';

//styles
import classes from './contact.module.css';

//components
import { Footer, Header, Offices } from '../../components';
import { Help, Top } from './components';

function Contact() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={classes.container}>
            <Header />
            <Top />
            <Help />
            <Offices />
            <Footer />
        </div>
    )
}

export default Contact