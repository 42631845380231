import React, { useEffect } from 'react';

//styles
import classes from './privacypolicy.module.css';

//components
import { Footer, Header } from '../../components';

//data
import privacyPolicy from '../../data/privacyPolicy.json';

function PrivacyPolicy() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const Sections = ({title, content}) => {
        return(
            <div className={classes.section}>
                <h3>{title}</h3>
                <p>{content}</p>
            </div>
        )
    };

    return (
        <div className={classes.container}>
            <Header />
            <div className={classes.main}>
                <h1>Gigmile's Privacy Policy</h1>
                <p className={classes.introText}>Thank you for using Gigmile!</p>
                <p className={classes.date}>LAST UPDATED: JUNE 22, 2022</p>
                <div className={classes.mainContainer}>
                    {privacyPolicy.privacyPolicy.map((i, idx) => (
                        <Sections
                            title={i.title}
                            content={i.content}
                            key={idx}
                        />
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default PrivacyPolicy