import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

//styles
import classes from './header.module.css';

//images
import logo from '../../assets/svgs/logo-red.svg';

//icons
import { FiMenu } from 'react-icons/fi';

//paths
import * as paths from '../../constants/paths';

//component
import Button from '../buttons/Button';
import SideDrawer from '../sideDrawer/SideDrawer';

function Header() {
    //initialize
    const navigate = useNavigate();

    //UI state
    const [showDrawer, setShowDrawer] = useState(false);

    return (
        <>
            <div className={classes.header}>
                <div className={classes.header_main}>
                    <img src={logo} alt='logo' onClick={() => navigate(paths.home)} />
                    <div className={classes.linkContainer}>
                        <div className={classes.mainLinkscontainer}>
                            <a href={paths.aboutUs}>About Us</a>
                            <a href={paths.careers}>Careers</a>
                            <a href={paths.impact}>Impact</a>
                            <Button text={'Contact Us'} onClick={() => navigate(paths.contact)} />
                        </div>
                        
                        <div className={classes.hamburger} onClick={() => setShowDrawer(true)}>
                            <FiMenu size={20} />
                        </div>
                    </div>
                </div>
                
            </div>
            {showDrawer && <SideDrawer close={() => setShowDrawer(false)} />}
        </>
    )
}

export default Header