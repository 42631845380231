import React from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';

//styles
import classes from './values.module.css';

//data
import values from '../../../../data/values.json';

const width = window.innerWidth;

function Values() {
    const paddedData = (width - 1200) / 2;

    const ValueItem = ({title, content, black}) => {
        return(
            <div className={`${classes.ValueItem} ${black && classes.ValueItem_dark}`}>
                <h3>{title}</h3>
                <p>{content}</p>
            </div>
        )
    };

    return (
        <div className={classes.container}>
            <div className={classes.main}>
                <h1>The values that drive <span>everything we do</span></h1>
                <p className={classes.introText}>At Gigmile, we're guided by a set of core values that inform everything we do. From empowering gig workers to fostering innovation and driving social impact.</p>
            </div>
            <ScrollContainer className={classes.bottom_grid} style={width > 1200 ? {paddingLeft: paddedData, paddingRight: paddedData} : {paddingLeft: 20, paddingRight: 20}}>
                {values.map((i, idx) => (
                    <ValueItem
                        title={i.title}
                        content={i.context}
                        black={i.black}
                        key={idx}
                    />
                ))}
            </ScrollContainer>
        </div>
    )
}

export default Values