import React from 'react';

//styles
import classes from './top.module.css';

//components
import { HeaderWhite, WhiteButton } from '../../../../components';

//images
import impactBg from '../../../../assets/images/impact-bg.jpg';

//paths
import * as paths from '../../../../constants/paths';

function Top() {
    return (
        <div className={classes.container} style={{backgroundImage: `url(${impactBg})`}}>
            <HeaderWhite />
            <div className={classes.main}>
                <div className={classes.top}>
                    <div />
                    OUR IMPACT
                </div>
                <h1>The Legacy Shall be, We Impacted Lives in Africa</h1>
                <p className={classes.introText}>Beyond job creation, we are highly committed to improving the resilience of Gig workers in Africa.</p>
                <a href={paths.aboutUs}> <WhiteButton text={'About Us'} onClick={() => console.log(' ')} /></a>
            </div>
        </div>
    )
}

export default Top