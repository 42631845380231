import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

//styles
import classes from './about.module.css';

//components
import { BannerVideo, Mission, Supporters, Team, Top, Values } from './components';
import { Button, Footer, IPadVideo, Offices } from '../../components';

//images
import guy_on_bike from '../../assets/images/guy-on-bike.jpg';
import thunderBolt1 from '../../assets/svgs/thunder-bolt1.svg';
import thunderBolt2 from '../../assets/svgs/thunder-bolt2.svg';


function About() {
    //initialize
    const navigate = useNavigate();
    const videoRef = useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleInteraction = () => {
        const video = videoRef.current;
    
        if (video) {
          video.play();
        }
    };

    return (
        <div className={classes.container} onClick={() => handleInteraction()}>
            <Top />
            <BannerVideo videoRef={videoRef} />
            <Mission />
            <IPadVideo poster={'https://res.cloudinary.com/ahumareze/image/upload/v1682009335/poster2_r2uhnf.jpg'} />
            <div className={classes.two_grids}>
                <div className={classes.two_grids_image}>
                    <img src={guy_on_bike} className={classes.guy_on_bike} alt='' />
                    <img src={thunderBolt1} className={classes.thunderBolt1} alt='' />
                    <img src={thunderBolt2} className={classes.thunderBolt2} alt='' />
                </div>
                <div className={classes.two_grids_content}>
                    <h1>We Seek to Make the Future of Work, <span>for Gig Workers in Africa!</span></h1>
                    <p>Beyond job creation, we are highly committed to improving the resilience of Gig workers. We create a community for gig workers to feel appreciated for their work, pursue the benefits of independent work and get ahead together.</p>
                    {/* <Button text={'Join the Revolution'} onClick={() => navigate(paths.contact)} /> */}
                </div>
            </div>
            <Values />
            <Team />
            <Offices />
            <Supporters />
            <Footer />
        </div>
    )
}

export default About