import React, { useEffect } from 'react';

//styles
import classes from './impact.module.css';

//components
import { Banner, EconomyImpact, Goals, Top } from './components';
import { Button, Footer } from '../../components';

//images
import user_testimonial from '../../assets/images/user_testimonial.png';

//paths
import * as paths from '../../constants/paths';

function Impact() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={classes.container}>
            <Top />
            <EconomyImpact />
            <div className={classes.testimonial_container}>
                <div className={classes.testimonial_banner}>
                    <img src={user_testimonial} alt='user_testimonial' />
                    <div className={classes.testimonial_banner_content}>
                        <h3>In 2022</h3>
                        <div className={classes.maincontents}>
                            <div>
                                <p>We enabled Gig workers to earn:</p>
                                <h2>$ 1,129,500</h2>
                            </div>
                            <div>
                                <p>% of Community with Mobile Accounts</p>
                                <h2>84% (2023 - 95%)</h2>
                            </div>
                            <div>
                                <p>Average Monthly Earning</p>
                                <h2>$ 300</h2>
                            </div>
                            <div>
                                <p>% of community with Health Insurance </p>
                                <h2>67% (2023 - 100%)</h2>
                            </div>
                            <div>
                                <p>Indirect lives impacted</p>
                                <h2>2,061</h2>
                            </div>
                            {/* <a href={paths.contact}><button>Join Us</button></a> */}
                        </div>
                    </div>
                </div>
            </div>
            <Banner />
            <Goals />
            <Footer />
        </div>
    )
}

export default Impact