import React from 'react';

//style
import classes from './extrabox.module.css';

//umages
import pinkHeart from "../../../../assets/svgs/pink-heart.svg";

//paths
import * as paths from '../../../../constants/paths';

function ExtraBox() {
    return (
        <div className={classes.container}>
            <img src={pinkHeart} alt='pink-heart' />
            <div className={classes.section}>
                <h3>What diversity and inclusion mean to us</h3>
                <p className={classes.mainContent}>There is no such thing as a perfect candidate and the best employees come from a wide range of backgrounds, experiences, and skill sets. Sendbird is a place where everyone can learn and grow. We respect, promote, and encourage diversity for equal employment opportunities and encourage you to apply if this role excites you.</p>
                <p className={classes.extraLink}>Need more info <a href={paths.contact}>reach us here</a></p>
            </div>
            <div className={classes.section}>
                <h3>The Future of Work at Gigmile</h3>
                <p className={classes.mainContent}>Gigmile values both work efficiency and team collaboration, and relationship building. For that purpose, we operate the new Future of Work 2.0 system. Those who live within an hour's commute to work will go to the office once a week to achieve synergy while autonomously choosing the best place and time to work together. Depending on your residence, organization, or nature of work, the frequency of attendance will be determined by consulting with the team. day one!</p>
            </div>
        </div>
    )
}

export default ExtraBox