import React, { useState } from 'react';

//styles
import classes from './top.module.css';

//components
import { Input, SelectInput, TextAreaInput } from '../../../../components';

//svg
import bolt from '../../../../assets/svgs/bolt.svg';
import eden from '../../../../assets/svgs/eden.svg';
import glovo from '../../../../assets/svgs/glovo.svg';
import jumia from '../../../../assets/svgs/jumia.svg';
import yango from '../../../../assets/svgs/yango.svg';
import uber from '../../../../assets/images/Uber.png';

const identity = [
    {name: 'I am a gig worker', value: 'I am a gig worker'},
    {name: 'I work for a Business', value: 'I work for a Business'},
    {name: 'Other', value: 'other'}
];

const countries = [
    {name: 'Nigeria 🇳🇬', value: 'Nigeria'},
    {name: 'Ghana 🇬🇭', value: 'Ghana'}
]

function Top() {
    //UI state
    const [errorMessage, setErrorMessage] = useState(false);

    //form state
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [stateIdentity, setIdentity] = useState(identity[0].value);
    const [country, setCountry] = useState(countries[0].value);
    const [message, setMessage] = useState('');

    const handleEmailClick = () => {
        if(name && phone && stateIdentity && country && message){
            sendEmail();
            setErrorMessage(false)
        }else{
            setErrorMessage(true);
        }
    };

    const sendEmail = () => {
        const subject = `New message from ${name}`;

        const body = `Work Email: ${email}, Phone: ${phone}, Identity: ${stateIdentity}, country: ${country}. Message: ${message}`;

        const mailtoUrl = `mailto:kayode@gigmile.com?subject=${encodeURIComponent(
            subject
        )}&body=${encodeURIComponent(body)}`;

        window.location.href = mailtoUrl;
    }

    return (
        <div className={classes.container}>
            <div className={classes.main}>
                <h1>Hey There!👋🏽 <br /> Got Questions? <br /> Let's Talk</h1>
                <p>Learn more about our products; get your questions answered. <br /> We'll help you get started.</p>
                <div className={classes.partners}>
                    Trusted by:
                    <div>
                        <img src={glovo} alt='glovo' /> <img src={jumia} alt='jumia' /> <img src={yango} alt='yango' /> <img src={eden} alt='eden' /> <img src={bolt} alt='bolt' /><img className={classes.uber} src={uber} alt='bolt' />
                    </div>
                </div>
            </div>
            <div className={classes.contactUsForm}>
                <h3>Contact Us form</h3>
                <p className={classes.contactUsFormIntroText}>Fill the form to connect with our representative, A customer? fill out the details as per your account's info and describe the issue you're facing.</p>

                <div className={classes.form}>
                    <Input title={'Full name *'} placeholder={'Your full name'} onChange={e => setName(e)} />
                    <div className={classes.two_grid}>
                        <Input title={'Work Email'} placeholder={'Your business email'} onChange={e => setEmail(e)} />
                        <Input title={'Phone number *'} placeholder={'phone number with country code'} onChange={e => setPhone(e)} />
                    </div>
                    <div className={classes.two_grid}>
                        <SelectInput title={'My Identity *'} placeholder={'My identity'} data={identity} onChange={e => setIdentity(e)} />
                        <SelectInput title={'Country *'} placeholder={'Your Country'} data={countries} onChange={e => setCountry(e)} />
                    </div>
                    <TextAreaInput title={'Type a message *'} placeholder={'Tell us more about your project, needs, complaints, partnership...'} onChange={e => setMessage(e)} />
                </div>
                {errorMessage && <p className={classes.errorMessage}>Make sure the form is filled out correctly before submitting</p> }
                <button onClick={handleEmailClick}>Let's Talk</button>
            </div>
        </div>
    )
}

export default Top