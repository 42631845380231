import React from 'react';

//styles
import classes from './buttons.module.css';

function Button({text, onClick}) {
    return (
        <button className={classes.container} onClick={onClick}>
            {text}
        </button>
    )
}

export default Button