import React from 'react';
import { useNavigate } from 'react-router-dom';

//styles
import classses from './footer.module.css';

//images
import logo from '../../assets/svgs/logo-white.svg';
import playstore_badge from '../../assets/svgs/playstore_badge.svg';

//link icons
import facebook from '../../assets/svgs/facebook.svg';
import instagram from '../../assets/svgs/instagram.svg';
import twitter from '../../assets/svgs/twitter.svg';
import linkdin from '../../assets/svgs/linkdin.svg';

//paths
import * as paths from '../../constants/paths';


function Footer() {
    //initialize
    const navigate = useNavigate();

    return (
        <div className={classses.container}>
            <div className={classses.main}>
                <div className={classses.main_top}>
                    <img src={logo} alt='logo' className={classses.gigmile_logo} />
                    <div className={classses.mainLinksContainer}>
                        <div className={classses.linksContainer}>
                            <h3>Product</h3>
                            <a href={paths.gammaMobilityApp} target="_blank">Gamma Mobility</a>
                        </div>
                        <div className={classses.linksContainer}>
                            <h3>Company</h3>
                            <a href={paths.aboutUs}>About Gigmile</a>
                            <a href={paths.careers}>Careers</a>
                            <a href={paths.impact}>Impact</a>
                            <a href={paths.contact}>Contact</a>
                            <a href={paths.blogs}>Blogs</a>
                            <a href={paths.press}>In the Press</a>
                        </div>
                        <div className={classses.linksContainer}>
                            <h3>Resources</h3>
                            <a href={paths.faq}>Help Center</a>
                            <a href={paths.termsAndConditions}>Terms of Service</a>
                            <a href={paths.privacyPolicy}>Privacy Policy</a>
                        </div>
                        <button onClick={() => navigate(paths.contact)}>Contact Us</button>
                    </div>
                </div>
                <div className={classses.main_bottom}>
                    <div className={classses.getApp}>
                        <h3>Get the Gamma Mobility App</h3>
                        <p>Everything you need to work, earn, and get paid at your call and be the proactive manager of your finances.</p>
                        <a href={paths.gammaMobilityApp} target="_blank"><img src={playstore_badge} alt={'playstore_badge'} /></a>
                    </div>
                    <div className={classses.bottom_2}>
                        <p className={classses.copyright}>Copyright © 2023 — Gigmile Technologies Limited</p>
                        <div className={classses.links}>
                            <a href='https://www.facebook.com/people/Gigmile/100083324691479/' target="_blank"> <img src={facebook} alt='' /></a>
                            <a href='https://www.instagram.com/gigmile_/' target="_blank"> <img src={instagram} alt='' /></a>
                            <a href='https://twitter.com/gigmile?lang=en' target="_blank"> <img src={twitter} alt='' /></a>
                            <a href='https://www.linkedin.com/company/gigmile/' target="_blank"> <img src={linkdin} alt='' /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer