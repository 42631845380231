import React, { useEffect } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll'

//styles
import classes from './testimonials.module.css';

//images
import worker1 from '../../../../assets/images/worker1.jpg';
import worker2 from '../../../../assets/images/worker2.jpg';
import worker3 from '../../../../assets/images/worker3.jpg';

//aos
import AOS from 'aos';
import 'aos/dist/aos.css';

const width = window.innerWidth;

function Testimonials() {

    const paddedData = (width - 1200) / 2;


    useEffect(() => {
        AOS.init()
    }, []);

    const Item = ({image, position, name, content}) => {
        return(
            <div className={classes.Item}>
                <div className={classes.contentContainer}>
                    <h3>{position}</h3>
                    <p>{content}</p>
                    <h4>{name}</h4>
                </div>
                <img src={image} />
            </div>
        )
    };

    return (
        <div className={classes.container}>
            <div className={classes.main}>
                <div className={classes.mainPadded}> <h1 data-aos="fade-up">Hear what our Gig workers have to say</h1> </div>
                <ScrollContainer className={classes.mainContainer} data-aos="fade-up" style={width > 700 ? {paddingLeft: paddedData, paddingRight: paddedData} : {paddingLeft: 20, paddingRight: 20}}>
                    <Item image={worker1} position={'Gigmile Captain'} name={'Will Anaba, Ghana'} content={'Gigmile is simply amazing. I am finally able to work with a company that truly understands the needs of gig workers. It has been a wonderful experience in the last 3 months, thank you!'} />
                    <Item image={worker2} position={'Gigmile Captain'} name={'Alao, Fadipe, Nigeria'} content={`I've been able to start saving for my son's education thanks to the higher pay I'm earning as a dispatch rider.`} />
                    <Item image={worker3} position={'Gigmile Captain'} name={'Samson, Ayobami, Nigeria'} content={`Not sure what to say, but I am happy and better off.`} />
                </ScrollContainer>
            </div>
        </div>
    )
}

export default Testimonials