import React from 'react';

//styles
import classes from './buttons.module.css';

function WhiteButton({text, onClick}) {
    return (
        <button className={classes.containerWhite} onClick={onClick}>
            {text}
        </button>
    )
}

export default WhiteButton