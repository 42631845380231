import React, { useEffect, useState } from 'react';

//components
import { DeliveryBusiness, GammaApp, Illustrations, InviteBanner, Services, Testimonials, Top, VideoModal } from './components';
import Faqs from './components/faqs/Faqs';
import { Footer, IPadVideo, IPadVideoHome } from '../../components';

//styles
import classes from './home.module.css';

function Home() {
    //UI state
    const [showModal, setshowModal] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={classes.container}>
            <Top watchVideo={() => setshowModal(true)} />
            <Illustrations />
            {/* <VideoSection /> */}
            <IPadVideoHome poster={'https://res.cloudinary.com/ahumareze/image/upload/v1682009335/poster1_lmqyf5.jpg'} />
            <Services />
            <GammaApp />
            <Faqs />
            <InviteBanner />
            {/* <DeliveryBusiness /> */}
            <Testimonials />
            <Footer />
            {showModal && <VideoModal close={() => setshowModal(false)} />}
        </div>
    )
}

export default Home