import React, { useEffect } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';

//styles
import classes from './careers.module.css';

//components
import { Goal, OpenRoles, Perks, Top } from './components';
import { Footer } from '../../components';

//imageshttps://res.cloudinary.com/ahumareze/image/upload/v1681849083/IMG_3687_bnqjsh.jpg
const career0 = 'https://res.cloudinary.com/ahumareze/image/upload/v1681849082/20230202_150213_exdlze.jpg';
const career1 = 'https://res.cloudinary.com/ahumareze/image/upload/v1681849083/IMG_3687_bnqjsh.jpg';
const career2 = 'https://res.cloudinary.com/ahumareze/image/upload/v1681849081/20230124_123943_1_umsguu.jpg';
const career3 = 'https://res.cloudinary.com/ahumareze/image/upload/v1681849081/20230203_104602_k8ej7t.jpg';
const career4 = 'https://res.cloudinary.com/ahumareze/image/upload/v1681849080/20230123_181029_1_e2wzju.jpg';
const career5 = 'https://res.cloudinary.com/ahumareze/image/upload/v1681849081/image_1_jddbqi.png';

const width = window.innerWidth;

function Careers() {
    const paddedData = (width - 1200) / 2;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    console.log('hello world')

    const viewRoles = () => {
        const element = document.getElementById('open-roles');
        if (element) {
            //Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    return (
        <div className={classes.container}>
            <Top viewRoles={viewRoles} />
            <ScrollContainer horizontal={true} className={classes.images} style={width > 1200 ? {paddingLeft: paddedData, paddingRight: paddedData} : {paddingLeft: 20, paddingRight: 20}}>
                <img src={career0} className={classes.career0} alt='' />
                <img src={career1} className={classes.career1} alt='' />
                <img src={career2} className={classes.career2} alt='' />
                <img src={career3} className={classes.career4} alt='' />
                <img src={career4} className={classes.career3} alt='' />
                <img src={career5} className={classes.career5} alt='' />
            </ScrollContainer>
            <Goal />
            <Perks />
            <OpenRoles />
            <Footer />
        </div>
    )
}

export default Careers