import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

//styles
import classes from './selectedrole.module.css';

//components
import { Button, Footer, Header } from '../../components';

//icons
import { FiChevronRight } from 'react-icons/fi';

//paths
import * as paths from '../../constants/paths';
import { Description, ExtraBox } from './components';

//data
import roles from '../../data/roles.json';


function SelectedRole() {
    //initialize
    const  { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    //UI state
    const [selectedData, setSelectedData] = useState(roles.roles[0]);

    useEffect(() => {
        const data = roles.roles;
        if(id){
            const item = data.find(obj => obj.link === `/${id}`);
            setSelectedData(item)
        }
    }, [id])

    return (
        <div className={classes.container}>
            <Header />
            <div className={classes.main}>
                <div className={classes.breadcrumbs}>
                    <a href={paths.careers}>Careers</a> <FiChevronRight /> <p>{selectedData.title}</p>
                </div>
                <h1>{selectedData.title}</h1>
                <p className={classes.location}>{selectedData.location}</p>
                <div className={classes.buttons}>
                    <a href={selectedData.applicationLink} target="_blank"><Button text={'Apply'} onClick={() => console.log('')} /></a>
                </div>
                <Description data={selectedData} />
                <ExtraBox />
            </div>
            <Footer />
        </div>
    )
}

export default SelectedRole