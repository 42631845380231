import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

//styles
import classes from './faqs.module.css';

//images
import gammaApp from '../../../../assets/svgs/gammaApp.svg';
import faq2 from '../../../../assets/svgs/faq2.svg';
import faq3 from '../../../../assets/svgs/faq3.svg';
import faq4 from '../../../../assets/svgs/faq4.svg';
import thunderBolt1 from '../../../../assets/svgs/thunder-bolt1.svg';
import thunderBolt2 from '../../../../assets/svgs/thunder-bolt2.svg';
import gammaAppNotification from '../../../../assets/svgs/gammaAppNotification.svg';

//components
import { Button } from '../../../../components';

//aos
import AOS from 'aos';
import 'aos/dist/aos.css';

//paths
import * as paths from '../../../../constants/paths';

//data
import faq from '../../../../data/faq.json';

//icons
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';

function Faqs() {
    //UI state
    const [selected, setSelected] = useState(0);

    useEffect(() => {
        AOS.init()
    }, []);

    const handleSelect = (id) => {
        if(selected !== id){
            setSelected(id)
        }else{
            setSelected(0)
        }
    }

    const FaqItem = ({question, content, id}) => {
        return(
            <div className={classes.FaqItem}>
                <div className={classes.FaqItem_top}  onClick={() => handleSelect(id)}>
                    {question}
                    <div>
                        {id === selected ? <AiOutlineMinus size={20} /> : <AiOutlinePlus size={20} />}
                    </div>
                </div>
                {id === selected &&
                    <div className={classes.answer}>
                        {content}
                    </div>
                }
            </div>
        )
    }

    const mainContainer = (
        <div className={classes.mainContainer}>
            <div className={`${classes.phoneContainer} ${selected === 0 && classes.displayPhone}`}>
                <img src={gammaApp} alt='gammaApp' className={classes.gammaAppImg} />
                <div className={classes.phoneContainer_canva}>
                    <img src={gammaAppNotification} alt='gammaAppNotification' className={classes.gammaAppNotification} />
                </div>
            </div>
            <div className={`${classes.phoneContainer} ${selected === 1 && classes.displayPhone}`}>
                <img src={gammaApp} alt='gammaApp' className={classes.gammaAppImg} />
                <div className={classes.phoneContainer_canva}>
                    <img src={gammaAppNotification} alt='gammaAppNotification' className={classes.gammaAppNotification} />
                </div>
            </div>
            <div className={`${classes.phoneContainer} ${selected === 2 && classes.displayPhone}`}>
                <img src={faq3} alt='gammaApp' className={classes.gammaAppImg} />
            </div>
            <div className={`${classes.phoneContainer} ${selected === 3 && classes.displayPhone}`}>
                <img src={faq2} alt='gammaApp' className={classes.gammaAppImg} />
            </div>
            <div className={`${classes.phoneContainer} ${selected === 4 && classes.displayPhone}`}>
                <img src={faq4} alt='gammaApp' className={classes.gammaAppImg} />
            </div>
        </div>
    )

    return (
        <div className={classes.container} data-aos="fade-up">
            {mainContainer}
            <div>
                <div className={classes.faqContainer}>
                    {faq.map((i, idx) => (
                        <FaqItem 
                            question={i.title}
                            content={i.reply}
                            id={i.id}
                            key={idx}
                        />
                    ))}
                </div>
                <p className={classes.extraText}>We strive to provide our Gig workers with an impeccable experience. Need help going forward? Our ever-reliable customer service representatives will attend to you! 🔥</p>
                <Link to={paths.gammaMobilityApp} target="_blank"><Button text={'Get the App'} /></Link>
            </div>
        </div>
    )
}

export default Faqs