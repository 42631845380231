import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//styles
import classes from './top.module.css';

//icons
import { BsPlayCircleFill } from 'react-icons/bs';

//svg
import bolt from '../../../../assets/svgs/bolt.svg';
import eden from '../../../../assets/svgs/eden.svg';
import glovo from '../../../../assets/svgs/glovo.svg';
import jumia from '../../../../assets/svgs/jumia.svg';
import yango from '../../../../assets/svgs/yango.svg';
import uber from '../../../../assets/images/Uber.png';
import homeBg from '../../../../assets/svgs/homeBg.svg';

//components
import { Button, Header } from '../../../../components';

//aos
import AOS from 'aos';
import 'aos/dist/aos.css';

//paths
import * as paths from '../../../../constants/paths';


function Top({watchVideo}) {
    //initialize
    const navigate = useNavigate()

    useEffect(() => {
        AOS.init()
    }, []);

    return (
        <div className={classes.container} style={{backgroundImage: `url(${homeBg})`}}>
            <Header />
            <div className={classes.main} data-aos="fade-up">
                <h1>Powering Africa's Gig Economy</h1>
                <p>We are on a mission to provide affordable, accessible and suitable financial services to Gig and informal workers who have historically been underserved and excluded from financial services</p>
                <div className={classes.buttons}>
                    <div className={classes.playButton} onClick={watchVideo}><BsPlayCircleFill size={22} /> Watch Video</div>
                    <Button text={'Contact Us'} onClick={() => navigate(paths.contact)} />
                </div>
                <div className={classes.partners}>
                    <p>Trusted By:</p> <img src={glovo} alt='glovo' /> <img src={jumia} alt='jumia' /> <img src={yango} alt='yango' /> <img src={eden} alt='eden' /> <img src={bolt} alt='bolt' /> <img src={uber} className={classes.uber} alt='bolt' />
                </div>
            </div>
        </div>
    )
}

export default Top