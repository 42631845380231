import React from 'react';

//styles
import classes from './faq.module.css';

//component
import { Footer, Header } from '../../components';

//images
import faq_banner from '../../assets/svgs/faq_banner.svg';

function FAQ() {
    return (
        <div className={classes.container}>
            <Header />
            <div className={classes.main}>
                <img className={classes.main_image} src={faq_banner} alt='' />
                <h2>We are curating the best Help Center Experience for you!</h2>
                <p>You'll find the most common questions as well as powerful tips on using Gigmile. We got you! 😊</p>
            </div>
            <Footer />
        </div>
    )
}

export default FAQ