import React from 'react';

//styles
import classes from './banner.module.css';

//img
const bannerImg = 'https://res.cloudinary.com/ahumareze/image/upload/v1682583412/Rectangle_18901_2_zucfls.jpg';

function Banner() {
    return (
        <div className={classes.container} style={{backgroundImage: `url(${bannerImg})`}}>
            <div className={classes.main}>
                <p className={classes.blog_title}>Gig Economy</p>
                <a href={'https://gigmile.medium.com/building-the-infrastructure-for-the-gig-economy-in-africa-how-does-gigmile-technologies-do-this-f5bbc98ad1d9'} target='_blank'><h2>Building the Infrastructure for the Gig Economy in Africa — How does Gigmile Technologies do this?</h2></a>
                <p className={classes.blog_intro}>The gig economy in Africa has the potential to change the economic outlook of the continent. Despite having enormous potential, the gig economy...</p>
                <ul>
                    <li>APR 12, 2023</li>
                    <li>3 MINS READ</li>
                </ul>
            </div>
        </div>
    )
}

export default Banner