import React, { useEffect, useState } from 'react';

//styles
import classes from './illustrations.module.css';

//images
import illustration_1 from '../../../../assets/images/illustration-1.png';
import illustration_2 from '../../../../assets/images/illustration-2.png';
import illustration_3 from '../../../../assets/images/illustration-3.png';

//svgs
import bikeModal from '../../../../assets/svgs/bike-modal.svg';
import deliveryModal from '../../../../assets/svgs/delivery-modal.svg';
import balanceModal from '../../../../assets/svgs/balance-modal.svg';

//aos
import AOS from 'aos';
import 'aos/dist/aos.css';

//dimensions
const width = window.innerWidth;

function Illustrations() {
    //UI state
    const [currentState, setCurrentState] = useState(1);

    useEffect(() => {
        const interval = setInterval(() => {
          setCurrentState(currentState => {
            if (currentState === 3) {
              return 1;
            } else {
              return currentState + 1;
            }
          });
        }, 7000);
    
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        AOS.init()
    }, []);

    let activeElement = (
        <div className={classes.slideIn}>
            <div className={classes.item1}>
                <img className={classes.illustration_1} src={illustration_1} alt='illustration-2' />
                <div className={classes.blur} />
                <div className={classes.main1}>
                    <img src={bikeModal} alt='bike-modal' />
                </div>
            </div>
        </div>
    );

    if(currentState === 2){
        activeElement = (
            <div className={classes.slideIn}>
                <div className={classes.item2}>
                    <img src={illustration_2} alt='illustration-2' />
                    <div className={classes.blur} />
                    <div className={classes.main2}>
                        <img src={deliveryModal} alt='bike-modal' />
                    </div>
                </div>
            </div>
        )
    }else if(currentState === 3){
        activeElement = (
            <div className={classes.slideIn}>
                <div className={classes.item2}>
                    <img src={illustration_3} alt='illustration-2' />
                    <div className={classes.blur} />
                    <div className={classes.main3}>
                        <img src={balanceModal} alt='bike-modal' />
                    </div>
                </div>
            </div>
        )
    }

    const main = (
        <div className={classes.container} data-aos="fade-up">
            <div className={classes.item1}>
                <img className={classes.illustration_1} src={illustration_1} alt='illustration-2' />
                <div className={classes.blur} />
                <div className={classes.main1}>
                    <img src={bikeModal} alt='bike-modal' />
                </div>
            </div>
            <div className={classes.item2}>
                <img src={illustration_2} alt='illustration-2' />
                <div className={classes.blur} />
                <div className={classes.main2}>
                    <img src={deliveryModal} alt='bike-modal' />
                </div>
            </div>
            <div className={classes.item2}>
                <img src={illustration_3} alt='illustration-2' />
                <div className={classes.blur} />
                <div className={classes.main3}>
                    <img src={balanceModal} alt='bike-modal' />
                </div>
            </div>
        </div>
    );

    const mobile = (
        <div className={classes.mobileContainer}>
            <div className={`${currentState === 1 ? classes.slideIn : classes.displayNone}`}>
                <div className={classes.item1}>
                    <img className={classes.illustration_1} src={illustration_1} alt='illustration-2' />
                    <div className={classes.blur} />
                    <div className={classes.main1}>
                        <img src={bikeModal} alt='bike-modal' />
                    </div>
                </div>
            </div>
            <div className={`${currentState === 2 ? classes.slideIn : classes.displayNone}`}>
                <div className={classes.item2}>
                    <img src={illustration_2} alt='illustration-2' />
                    <div className={classes.blur} />
                    <div className={classes.main2}>
                        <img src={deliveryModal} alt='bike-modal' />
                    </div>
                </div>
            </div>
            <div className={`${currentState === 3 ? classes.slideIn : classes.displayNone}`}>
                <div className={classes.item2}>
                    <img src={illustration_3} alt='illustration-2' />
                    <div className={classes.blur} />
                    <div className={classes.main3}>
                        <img src={balanceModal} alt='bike-modal' />
                    </div>
                </div>
            </div>
        </div>
    )

    return (
        width > 1000 ? main : mobile
    )
}

export default Illustrations