import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//components
import { Button } from '../../../../components';

//styles
import classes from './services.module.css';

//images
import service1 from '../../../../assets/svgs/service1.svg';
import service2 from '../../../../assets/svgs/service2.svg';
import service3 from '../../../../assets/svgs/service3.svg';

//aos
import AOS from 'aos';
import 'aos/dist/aos.css';

//paths
import * as paths from '../../../../constants/paths';

//data
import services from '../../../../data/services.json';


function Services() {
    //initialize
    const navigate = useNavigate()

    useEffect(() => {
        AOS.init()
    }, []);

    const Item = ({img, title, content}) => {
        return(
            <div className={classes.Item} data-aos="fade-up">
                <img src={img} alt={'service-image'} />
                <div className={classes.contentContainer}>
                    <h3>{title}</h3>
                    <p>{content}</p>
                </div>
            </div>
        )
    };

    return ( 
        <div className={classes.container} >
            <h1 data-aos="fade-up">{services.services.title}<span> {services.services.title_span}</span></h1>
            <p className={classes.introText} data-aos="fade-up">{services.services.sub_text}</p>
            {/* <Button text={'Join the Revolution'} onClick={() => navigate(paths.contact)} /> */}
            <div className={classes.servicesGrid}>
                <Item img={service1} title={services.services.content[0].title} content={services.services.content[0].text} />
                <Item img={service2} title={services.services.content[1].title} content={services.services.content[1].text} />
                <Item img={service3} title={services.services.content[2].title} content={services.services.content[2].text} />
            </div>
        </div>
    )
}

export default Services