import React, { useEffect, useRef, useState } from 'react';

//styles
import classes from './ipadvideo.module.css';

//images
import ipad_bg from '../../assets/svgs/ipad-bg.svg';
import curvedArrow from '../../assets/svgs/curved-arrow.svg';
import orangeDots from '../../assets/svgs/orange-dots.png';

//icons
import { BsFillPauseCircleFill, BsFillPlayCircleFill } from 'react-icons/bs';

//video link
const embedId = 'RIouwsRWLwo';

function IPadVideoHome({poster}) {
    //UI state
    const [showCover, setShowCover] = useState(true);
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef(null);

    const playVideo = () => {
        videoRef.current.play();
        setShowCover(false);
        setIsPlaying(true);
    };

    const pauseVideo = () => {
        videoRef.current.pause();
        setShowCover(true);
        setIsPlaying(false);
    }

    const thumbnailStyle = {
        borderRadius: '10px', // set the border radius to 10px
    };

    const handleMouseEnter = () => {
        setShowCover(true)
    }
    const handleMouseExit = () => {
        if(isPlaying){
            setShowCover(false)
        }
    }

    const coverContainer = (
        <div className={classes.coverContainer}>
            <div className={classes.coverMainContents}>
                {/* <p>We are democratizing access to opportunities in Africa's rising Gig Economy.</p> */}
                {isPlaying ? 
                    <div className={classes.mainPlay}>
                        <BsFillPauseCircleFill size={80} className={classes.playIcon} onClick={pauseVideo} />
                        Pause
                    </div>
                    :
                    <div className={classes.mainPlay}>
                        <BsFillPlayCircleFill size={80} className={classes.playIcon} onClick={playVideo} />
                        Play
                    </div>
                }
            </div>
        </div>
    )

    return (
        <div className={classes.container}>
            <div className={classes.phone}>
                <img src={ipad_bg} alt='ipad-bg' />
                <div className={classes.cover} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseExit}>
                    {/* {showCover && coverContainer} */}
                    <iframe
                        className={classes.youtubeVideo}
                        src={`https://www.youtube.com/embed/${embedId}`}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                    />
                </div>
            </div>
            <img src={orangeDots} className={classes.orangeDots} alt=' ' />
        </div>
    )
}

export default IPadVideoHome