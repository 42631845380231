import React, { useEffect } from 'react';

//styles
import classes from './top.module.css';

//components
import { Button, Header } from '../../../../components';

//images
import homeBg from '../../../../assets/svgs/homeBg.svg';

//aos
import AOS from 'aos';
import 'aos/dist/aos.css';

function Top({viewRoles}) {

    useEffect(() => {
        AOS.init()
    }, []);

    return (
        <div className={classes.container} style={{backgroundImage: `url(${homeBg})`}}>
            <div className={classes.mainContents}>
                <Header />
                <div className={classes.main}>
                    <h1 data-aos="fade-up">Gigmile is Growing Everyday, Grow with Us!</h1>
                    <p data-aos="fade-up">At Gigmile we seek talented individuals passionate about shaping the future of work in Africa. We offer opportunities to work on cutting-edge projects, collaborate with talented colleagues, and make a real difference in the lives of customers.</p>
                    <Button text={'See Open Roles'} onClick={viewRoles} />
                </div>
            </div>
        </div>
    )
}

export default Top