import React from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';

//styles
import classes from './goal.module.css';

const width = window.innerWidth;

function Goals() {
    const paddedData = (width - 1200) / 2;

    return (
        <div className={classes.container}>
            <div className={classes.topContainer}>
                <h1>Gigmile's Sustainable Development Goals</h1>
                <p>Gigmile's commitment to empowering gig workers and supporting the growth of the gig economy in Africa aligns with several important United Nations Sustainable Development Goals. We are building a more sustainable and equitable future for underserved communities while contributing to the achievement of the global goals.</p>
            </div>
            <ScrollContainer className={classes.bottom_grid} style={width > 1200 ? {paddingLeft: paddedData, paddingRight: paddedData} : {paddingLeft: 20, paddingRight: 20}}>
                <img className={classes.sdgItem} src='https://res.cloudinary.com/ahumareze/image/upload/v1681983504/image_22_iuehal.jpg' />
                <img className={classes.sdgItem} src='https://res.cloudinary.com/ahumareze/image/upload/v1681983504/image_24_mshxel.jpg' />
                <img className={classes.sdgItem} src='https://res.cloudinary.com/ahumareze/image/upload/v1681983504/image_25_d9waju.jpg' />
                <img className={classes.sdgItem} src='https://res.cloudinary.com/ahumareze/image/upload/v1681983504/image_26_d4m2ch.jpg' />
                <img className={classes.sdgItem} src='https://res.cloudinary.com/ahumareze/image/upload/v1681983504/image_23_xfcir3.jpg' />
                <img className={classes.sdgItem} src='https://res.cloudinary.com/ahumareze/image/upload/v1681983504/image_27_srrvp3.jpg' />
            </ScrollContainer>
        </div>
    )
};

export default Goals