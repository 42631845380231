import React, { useState } from 'react';

//styles
import classes from './sidedrawer.module.css';

//icons
import { FiX } from 'react-icons/fi';

//paths
import * as paths from '../../constants/paths';

function SideDrawer({close}) {
    //UI state
    const [hideDrawer, setHidedrawer] = useState(false);

    const closeHandler = () => {
        setHidedrawer(true)
        setTimeout(() => {
            setHidedrawer(false);
            close()
        }, 500)
    }

    return (
        <div className={classes.container}>
            <div className={classes.close} onClick={() => closeHandler()} />
            <div className={`${classes.main} ${hideDrawer && classes.hideDrawer}`}>
                <div className={classes.top}>
                    <div className={classes.closeIcon} onClick={() => closeHandler()}>
                        <FiX size={20} />
                    </div>
                </div>
                <div className={classes.linksContainer}>
                    <a href={paths.aboutUs}><div className={classes.link}>About Us</div></a>
                    <a href={paths.careers}><div className={classes.link}>Careers</div></a>
                    <a href={paths.impact}><div className={classes.link}>Impact</div></a>
                    <a href={paths.contact}><button>Contact Us</button></a>
                </div>
            </div>
        </div>
    )
}

export default SideDrawer