export const home = '/';
export const aboutUs = '/about-us';
export const careers = '/careers';
export const contact = '/contact-us';
export const impact = '/impact';
export const privacyPolicy = '/privacy-policy';
export const termsAndConditions = '/terms-and-conditions';
export const blogs = '/blogs';
export const gammaMobilityApp = 'https://play.google.com/store/apps/details?id=com.gigmile.app';
export const press = '/in-the-press';
export const faq = '/frequently-asked-questions';