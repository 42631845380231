import React from 'react';

//styles
import classes from './team.module.css';

//data
import team from '../../../../data/team.json'

function Team() {

    const Item = ({name, position, image}) => {
        return(
            <div className={classes.Item}>
                <img src={image} alt={name} />
                <h3>{name}</h3>
                <p>{position}</p>
            </div>
        )
    };

    return (
        <div className={classes.container}>
            <h1>The amazing team behind <span>Gigmile</span></h1>
            <p className={classes.introText}>We are a team of builders and operators with deep and first-hand experience in this space.</p>
            <div className={classes.userContainer}>
                {team.team.map((i, idx) => (
                    <Item 
                        name={i.name}
                        position={i.position}
                        image={i.image}
                        key={idx}
                    />
                ))}
            </div>
        </div>
    )
}

export default Team