import React, { useEffect, useState } from 'react';

//styles
import classes from './videomodal.module.css';
import { FiX } from 'react-icons/fi';

const embedId = 'RIouwsRWLwo';

const width = window.innerWidth;
const height = window.innerHeight;

function VideoModal({close}) {
    return (
        <div className={classes.container}>
            <div className={classes.main} >
                <div className={classes.close} onClick={close}> <FiX size={20} /> </div>
                <iframe
                    className={classes.video}
                    src={`https://www.youtube.com/embed/${embedId}`}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                />
            </div>
        </div>
    )
}

export default VideoModal