import React from 'react';

//styles
import classes from './inputs.module.css';

function TextAreaInput({title, placeholder, onChange}) {
    return (
        <div className={classes.container}>
            <p>{title}</p>
            <textarea placeholder={placeholder} onChange={e => onChange(e.target.value)} />
        </div>
    )
}

export default TextAreaInput