import React from 'react';
import { Route, Routes } from 'react-router-dom';

//pages
import { About, Blogs, Careers, Contact, FAQ, Home, Impact, Press, PrivacyPolicy, SelectedRole, TermsAndConditoins } from './pages';

//paths
import * as paths from './constants/paths';

function App() {
  console.log('rendered')
  return (
    <Routes>
      <Route path={paths.home} element={<Home />} />
      <Route path={paths.aboutUs} element={<About />} />
      <Route path={paths.careers} element={<Careers />} />
      <Route path={paths.contact} element={<Contact />} />
      <Route path={paths.careers + '/:id'} element={<SelectedRole />} />
      <Route path={paths.impact} element={<Impact />} />
      <Route path={paths.privacyPolicy} element={<PrivacyPolicy />} />
      <Route path={paths.termsAndConditions} element={<TermsAndConditoins />} />
      <Route path={paths.blogs} element={<Blogs />} />
      <Route path={paths.press} element={<Press />} />
      <Route path={paths.faq} element={<FAQ />} />
    </Routes>
  )
}

export default App;