import React from 'react';

//styles
import classes from './banner.module.css';

//images
import impactWoman from '../../../../assets/images/impact-woman.jpg';

//vectors
import vector1 from '../../../../assets/svgs/impact-vector1.svg';
import vector2 from '../../../../assets/svgs/impact-vector2.svg';
import vector3 from '../../../../assets/svgs/impact-vector3.svg';
import vector4 from '../../../../assets/svgs/impact-vector4.svg';

//components
import { Button } from '../../../../components';

//paths
import * as paths from '../../../../constants/paths';

function Banner() {
    return (
        <div className={classes.container}>
            <div className={classes.main}>
                <h1>Empowering the Informal workers with skills <span>and financial services to kickstart their lives and career.</span></h1>
                <div className={classes.bottom}>
                    <div className={classes.imagesContainer}>
                        <img className={classes.impactWoman} src={impactWoman} alt=' ' />
                        <img src={vector1} className={classes.vector1} alt='vectors' />
                        <img src={vector2} className={classes.vector2} alt='vectors' />
                        <img src={vector3} className={classes.vector3} alt='vectors' />
                        <img src={vector4} className={classes.vector4} alt='vectors' />
                    </div>
                    <div className={classes.bottomContext}>
                        <p>Join us in our mission if you are looking to participate in the gig economy in Africa. We welcome collaboration and support in creating a more equitable and prosperous world. Together, we can build a brighter future for all!</p>
                        <a href={paths.contact}><Button text={'Contact Us'} onClick={() => console.log('hello world')} /></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner