import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//components
import { Button } from '../../../../components';

//styles
import classes from './deliverybusiness.module.css';

//images
import deliveryBusiness from '../../../../assets/images/deliveryBusiness.svg';

//aos
import AOS from 'aos';
import 'aos/dist/aos.css';

//paths
import * as paths from '../../../../constants/paths';

function DeliveryBusiness() {
    //initialize
    const navigate = useNavigate();

    useEffect(() => {
        AOS.init()
    }, []);

    const BlockItem = ({title, text, black}) => {
        return(
            <div className={`${classes.BlockItem} ${black && classes.BlockItemBlack}`} data-aos="fade-up">
                <h3>{title}</h3>
                <p>Despite its estimated current market size of $115 billion, which is projected to grow to $712 billion by 2050. <br /> We believe that the existing and services are insufficient to achieve sustainable growth. </p>
            </div>
        )
    };

    return (
        <div className={classes.container}>
            <h1 data-aos="fade-up">Are you a Business With <br /> <span>Delivery Needs?</span></h1>
            <p className={classes.introText} data-aos="fade-up">Let us take care of your same-day and on-demand deliveries. Our innovative online platform and passionate customer service team ensure an anxiety-free logistics process for our business clients, from beginning to end.</p>
            <button data-aos="fade-up" onClick={() => navigate(paths.contact)}>Get Started</button>
            <img className={classes.deliveryBusiness} src={deliveryBusiness} alt={'deliveryBusiness'} data-aos="fade-up" />
            <div className={classes.blocks} data-aos="fade-up">
                <BlockItem title={'Dedicated Gigmile captain officer 🔥'} black />
                <BlockItem title={'Capacity for 1 to 3,000 deliveries monthly'} />
                <BlockItem title={'Live map tracking & 24/7 customer care assistance'} />
            </div>
        </div>
    )
}

export default DeliveryBusiness;