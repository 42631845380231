import React from 'react';

//styles
import classes from './inputs.module.css';

function SelectInput({title, data, placeholder, onChange}) {
    return (
        <div className={classes.container}>
            <p>{title}</p>
            <select placeholder={placeholder} onChange={e => onChange(e.target.value)}>
                {data.map((i, idx) => (
                    <option value={i.value} key={idx}>{i.name}</option>
                ))}
            </select>
        </div>
    )
}

export default SelectInput