import React from 'react';

//styles
import classes from './inputs.module.css'

function Input({title, placeholder, onChange}) {
    return (
        <div className={classes.container}>
            <p>{title}</p>
            <input placeholder={placeholder} onChange={e => onChange(e.target.value)} />
        </div>
    )
}

export default Input