import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

//styles
import classes from './gammaapp.module.css';

//images
import gammapp from '../../../../assets/images/gammapp-bg.jpg';
import gammaAppMobile from '../../../../assets/images/gammaApp-bg-mobile.jpg';

//components
import { Button } from '../../../../components';

//aos
import AOS from 'aos';
import 'aos/dist/aos.css';

//paths
import * as paths from '../../../../constants/paths';

function GammaApp() {

    useEffect(() => {
        AOS.init()
    }, []);

    return (
        <div className={classes.container} style={{backgroundImage: `url(${gammapp})`}}>
            <img className={classes.gammaAppMobile} src={gammaAppMobile} alt=''/>
            <div className={classes.canva}>
                <div className={classes.main} data-aos="fade-up">
                    <div className={classes.top}>
                        <div /> As a gig worker 
                    </div>
                    <h1>The unpredictability of your work shouldn't affect your earnings.</h1>
                    <p>With the Gamma Mobility App, you can take charge of your finances by working, earning, and receiving payments on your own terms.</p>
                    {/* <Link to={paths.gammaMobilityApp} target="_blank"><Button text={'Get the App'} /></Link> */}
                </div>
            </div>
        </div>
    )
}

export default GammaApp